import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Crypto Swap": "Crypto Swap",
      "USDT to OPG": "USDT to OPG",
      "OPG to USDT": "OPG to USDT",
      "Network": "Network",
      "USDT Amount": "USDT Amount",
      "OPG Amount": "OPG Amount",
      "OPG Address (To)": "OPG Address (To)",
      "USDT Address (To)": "USDT Address (To)",
      "Sender Address": "Sender Address",
      "You will receive": "You will receive",
      "You will send": "You will send",
      "Swap": "Swap",
      "Please send": "Please send",
      "Swap Instructions": "Swap Instructions",
      "instruction_step_1": "1. Select swap type",
      "instruction_step_2": "2. Choose network for USDT",
      "instruction_step_3": "3. Enter the amount to send",
      "instruction_step_4": "4. Enter the recipient address",
      "instruction_step_5": "5. Enter the sender's address",
      "instruction_step_6": "6. Send the coins to the provided address, you can use the QR code for convenience.",
      "instruction_step_7": "7. Wait a few minutes for the swap to complete, then check your balance."
    }
  },
  ru: {
    translation: {
      "Crypto Swap": "Крипто Свап",
      "USDT to OPG": "USDT в OPG",
      "OPG to USDT": "OPG в USDT",
      "Network": "Сеть",
      "USDT Amount": "Количество USDT",
      "OPG Amount": "Количество OPG",
      "OPG Address (To)": "Адрес OPG (Куда)",
      "USDT Address (To)": "Адрес USDT (Куда)",
      "Sender Address": "Адрес отправителя",
      "You will receive": "Вы получите",
      "You will send": "Вы отправите",
      "Swap": "Обмен",
      "Please send": "Пожалуйста отправьте",
      "Swap Instructions": "Инструкции по обмену",
      "instruction_step_1": "1. Выберите тип свапа",
      "instruction_step_2": "2. Выберите сеть для USDT",
      "instruction_step_3": "3. Укажите количество для отправки",
      "instruction_step_4": "4. Укажите адрес получателя",
      "instruction_step_5": "5. Укажите адрес отправителя",
      "instruction_step_6": "6. Отправьте монеты на указанный адрес, для удобства можно воспользоваться QR-кодом.",
      "instruction_step_7": "7. Ожидайте выполнение обмена в течение нескольких минут, затем проверьте поступление средств на своём балансе."
    }
  },
  de: {
    translation: {
      "Crypto Swap": "Krypto Swap",
      "USDT to OPG": "USDT zu OPG",
      "OPG to USDT": "OPG zu USDT",
      "Network": "Netzwerk",
      "USDT Amount": "USDT Betrag",
      "OPG Amount": "OPG Betrag",
      "OPG Address (To)": "OPG Adresse (An)",
      "USDT Address (To)": "USDT Adresse (An)",
      "Sender Address": "Absenderadresse",
      "You will receive": "Sie erhalten",
      "You will send": "Sie senden",
      "Swap": "Tauschen",
      "Please send": "Bitte senden",
      "Swap Instructions": "Swap-Anweisungen",
      "instruction_step_1": "1. Wählen Sie den Swap-Typ",
      "instruction_step_2": "2. Wählen Sie das Netzwerk für USDT",
      "instruction_step_3": "3. Geben Sie den zu sendenden Betrag ein",
      "instruction_step_4": "4. Geben Sie die Empfängeradresse ein",
      "instruction_step_5": "5. Geben Sie die Absenderadresse ein",
      "instruction_step_6": "6. Senden Sie die Coins an die angegebene Adresse, Sie können den QR-Code zur Vereinfachung verwenden.",
      "instruction_step_7": "7. Warten Sie ein paar Minuten, bis der Swap abgeschlossen ist, und überprüfen Sie dann Ihr Guthaben."
    }
  },
  es: {
    translation: {
      "Crypto Swap": "Intercambio Cripto",
      "USDT to OPG": "USDT a OPG",
      "OPG to USDT": "OPG a USDT",
      "Network": "Red",
      "USDT Amount": "Cantidad de USDT",
      "OPG Amount": "Cantidad de OPG",
      "OPG Address (To)": "Dirección de OPG (A)",
      "USDT Address (To)": "Dirección de USDT (A)",
      "Sender Address": "Dirección del remitente",
      "You will receive": "Recibirá",
      "You will send": "Enviará",
      "Swap": "Intercambiar",
      "Please send": "Por favor, envíe",
      "Swap Instructions": "Instrucciones de intercambio",
      "instruction_step_1": "1. Seleccione el tipo de intercambio",
      "instruction_step_2": "2. Elija la red para USDT",
      "instruction_step_3": "3. Ingrese la cantidad a enviar",
      "instruction_step_4": "4. Ingrese la dirección del destinatario",
      "instruction_step_5": "5. Ingrese la dirección del remitente",
      "instruction_step_6": "6. Envíe las monedas a la dirección proporcionada, puede usar el código QR para mayor comodidad.",
      "instruction_step_7": "7. Espere unos minutos para que se complete el intercambio y luego verifique su saldo."
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // Язык по умолчанию
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // React уже экранирует значения
    }
  });

export default i18n;
