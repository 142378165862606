import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { QRCodeCanvas } from 'qrcode.react'; // Используем именованный экспорт
import './SwapForm.css'; // Подключаем стили
import { useTranslation } from 'react-i18next'; // Для перевода
import './i18n'; // Импортируем настройки i18n

const SwapForm = () => {
    const { t, i18n } = useTranslation(); // Используем хук для перевода
    const [swapDirection, setSwapDirection] = useState('usdtToOpg'); // Направление свапа
    const [usdtAmount, setUsdtAmount] = useState('');
    const [opgAmount, setOpgAmount] = useState('');
    const [opgAddress, setOpgAddress] = useState('');
    const [senderAddress, setSenderAddress] = useState(''); // Адрес отправителя
    const [network, setNetwork] = useState('BSC'); // Устанавливаем BSC по умолчанию
    const [resultAmount, setResultAmount] = useState(0); // Итоговая сумма
    const [depositAddress, setDepositAddress] = useState('');
    const [swapStatus, setSwapStatus] = useState(''); // Статус свапа

    // Рассчитываем сумму на основе 1:1 курса для обоих направлений
    useEffect(() => {
        if (swapDirection === 'usdtToOpg' && usdtAmount) {
            setResultAmount(usdtAmount); // 1:1 курс
        } else if (swapDirection === 'opgToUsdt' && opgAmount) {
            setResultAmount(opgAmount); // 1:1 курс
        }
    }, [usdtAmount, opgAmount, swapDirection]);

    const handleSwap = async (e) => {
        e.preventDefault();
        setSwapStatus(t('swapping'));

        try {
            const data = swapDirection === 'usdtToOpg'
                ? { usdtAmount, opgAddress, senderAddress, network }
                : { opgAmount, usdtAddress: opgAddress, senderAddress, network };

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/swap`, data);
            setDepositAddress(response.data.depositAddress);

            // Проверяем подтверждение от бэкенда
            const swapConfirmation = await axios.get(`${process.env.REACT_APP_API_URL}/swap/confirm`, {
                params: { depositAddress: response.data.depositAddress }
            });

            if (swapConfirmation.data.status === 'success') {
                setSwapStatus(t('swap_success'));
            } else {
                setSwapStatus(''); // Убираем swap_error
            }
        } catch (error) {
            setSwapStatus(t('swap_error')); // Можем убрать swap_error, если не нужно
        }
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng); // Меняем язык
    };

    return (
        <div className="swap-container">
            <h1 className="title">{t('Crypto Swap')}</h1>

            {/* Выбор направления свапа */}
            <div className="swap-direction">
                <button
                    className={swapDirection === 'usdtToOpg' ? 'active' : ''}
                    onClick={() => setSwapDirection('usdtToOpg')}
                >
                    {t('USDT to OPG')}
                </button>
                <button
                    className={swapDirection === 'opgToUsdt' ? 'active' : ''}
                    onClick={() => setSwapDirection('opgToUsdt')}
                >
                    {t('OPG to USDT')}
                </button>
            </div>

            <form onSubmit={handleSwap} className="swap-form">
                {/* Выбор сети */}
                <div className="form-group">
                    <label>{t('Network')}:</label>
                    <select value={network} onChange={(e) => setNetwork(e.target.value)} required>
                        <option value="BSC">BSC</option>
                        <option value="TRC-20">TRC-20</option>
                        <option value="TON">TON</option>
                    </select>
                    {swapDirection === 'opgToUsdt' && <p>{t('Network only for USDT')}</p>}
                </div>

                {/* Ввод суммы для свапа */}
                {swapDirection === 'usdtToOpg' ? (
                    <div className="form-group">
                        <label>{t('USDT Amount')}:</label>
                        <input
                            type="number"
                            value={usdtAmount}
                            onChange={(e) => setUsdtAmount(e.target.value)}
                            required
                        />
                    </div>
                ) : (
                    <div className="form-group">
                        <label>{t('OPG Amount')}:</label>
                        <input
                            type="number"
                            value={opgAmount}
                            onChange={(e) => setOpgAmount(e.target.value)}
                            required
                        />
                    </div>
                )}

                {/* Адрес получателя */}
                <div className="form-group">
                    <label>{swapDirection === 'usdtToOpg' ? t('OPG Address (To)') : t('USDT Address (To)')}:</label>
                    <input
                        type="text"
                        value={opgAddress}
                        onChange={(e) => setOpgAddress(e.target.value)}
                        required
                    />
                </div>

                {/* Адрес отправителя */}
                <div className="form-group">
                    <label>{t('Sender Address')}:</label>
                    <input
                        type="text"
                        value={senderAddress}
                        onChange={(e) => setSenderAddress(e.target.value)}
                        required
                    />
                </div>

                {/* Отображение итоговой суммы */}
                <div className="form-group">
                    <label>{swapDirection === 'usdtToOpg' ? t('You will receive') : t('You will send')}:</label>
                    <p>{resultAmount} {swapDirection === 'usdtToOpg' ? 'OPG' : 'USDT'}</p>
                </div>

                <button type="submit" className="swap-button">{t('Swap')}</button>
            </form>

            {depositAddress && (
                <div className="result">
                    <p>{t('Please send')}:</p>
                    <p className="deposit-address">{depositAddress}</p>
                    {/* Генерация QR-кода для адреса */}
                    <div className="qr-code-container">
                        <QRCodeCanvas value={depositAddress} size={128} />
                    </div>
                </div>
            )}

            {/* Отображение статуса только при успешном свапе */}
            {swapStatus && swapStatus !== 'swap_error' && <p className="status">{swapStatus}</p>}

            {/* Переключатель языков */}
            <div className="language-switcher">
                <button onClick={() => changeLanguage('en')}>EN</button>
                <button onClick={() => changeLanguage('ru')}>RU</button>
                <button onClick={() => changeLanguage('de')}>DE</button>
                <button onClick={() => changeLanguage('es')}>ES</button>
            </div>

            {/* Инструкция по свапу */}
            <div className="instructions">
                <h3>{t('Swap Instructions')}</h3>
                <p>{t('instruction_step_1')}</p>
                <p>{t('instruction_step_2')}</p>
                <p>{t('instruction_step_3')}</p>
                <p>{t('instruction_step_4')}</p>
                <p>{t('instruction_step_5')}</p>
                <p>{t('instruction_step_6')}</p>
                <p>{t('instruction_step_7')}</p>
            </div>
        </div>
    );
};

export default SwapForm;
