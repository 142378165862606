import React from 'react';
import SwapForm from './components/SwapForm';

function App() {
    return (
        <div className="App">
            
            <SwapForm />
        </div>
    );
}

export default App;
